import React from "react";
import styled from "styled-components"
import {colors} from "../utils/styles";

const LinkContainer = styled.div`
  display: flex;
  width: 2rem;
  justify-content: center; 
  text-align: center;
`;

const Link = styled.a`
  color: #fff;
  transition: font-size 100ms;
  display: inline-block;
  margin: 0.7rem;
  font-size: 1.5rem;
  &:hover {
    color: ${colors.link};
  }
`;

const SocialLink = ({link, icon}) => (
    <LinkContainer>
        <Link href={link} rel={'noopener'} target={'_blank'}>{icon}</Link>
    </LinkContainer>
);

export default SocialLink