import React from "react";
import AopLogo from "./aopLogo";
import styled from "styled-components";
import IconNavigation from "./iconNavigation";
import {getFluidGatsbyImage} from "gatsby-storyblok-image";
import Img from "gatsby-image";
import NavigationButton from "./navigationButton";
import {colors} from "../utils/styles";
import {breakpoints} from "../utils/responsive";

const HeaderWrapper = styled.header`
  min-height: 8rem;
  @media(min-width: ${breakpoints.md}px) {
    min-height: 25rem;  
  }
`;

const LogoWrapperOuter = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const LogoWrapper = styled.div`
  padding: 1rem 0;
  max-width: 100px;
  margin: 0 auto;
  
  @media(min-width: ${breakpoints.md}px) {
    padding: 10rem 0;
    max-width: 200px;  
  }
`;

const HeaderImageWrapper = styled.div`
  position: relative;
  &.with-gradient, &.with-overlay {
    &:after {
    z-index: 2;
      content: '';
      position: absolute;
      height: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(48,28,61,0) 0%, rgba(48,28,61,1) 100%);    
    }
  }
  
  &.with-overlay {
    &:after {
      height: 100%;
      background: linear-gradient(180deg, rgba(48,28,61,0.8) 0%, rgba(48,28,61,0.8) 80%, rgba(48,28,61,1) 100%);    
    }  
  }
  
  &.grayscale img {
    filter: grayscale();
  }
`;

const HeaderTitle = styled.h1`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 25vw;
  color: ${colors.headline};
  z-index: 5;
  font-size: 1.71rem;
  font-family: 'Bebas Neue', cursive;
  line-height: 1.25;
  
  @media(min-width: ${breakpoints.md}px) {
    font-size: 2.8571428571rem;
  }
`;

const Header = ({toggleNavigation, navigationIsVisible, headerImage, headerImageGrayScale, headerImageWithOverlay, headerImageWithGradient, textOnHeaderImage}) => {
    const headerImageFluid = headerImage ? getFluidGatsbyImage(headerImage, {
        maxWidth: 900
    }) : null;
    return <HeaderWrapper>
        {headerImageFluid && <HeaderImageWrapper
            className={`${headerImageWithGradient ? 'with-gradient' : ''} ${headerImageWithOverlay ? 'with-overlay' : ''} ${headerImageGrayScale ? 'grayscale' : ''}`}>
            <Img fluid={headerImageFluid}/>
            {textOnHeaderImage && <HeaderTitle>{textOnHeaderImage}</HeaderTitle>}
        </HeaderImageWrapper>}
        <NavigationButton onClick={() => toggleNavigation(!navigationIsVisible)}/>
        <LogoWrapperOuter>
            <LogoWrapper>
                <AopLogo alt={'AOP Logo'}/>
            </LogoWrapper>
        </LogoWrapperOuter>
        <IconNavigation/>
    </HeaderWrapper>
};

export default Header;
