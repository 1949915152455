import React from "react";
import SocialLink from "./social-link";
import {FaFacebookSquare, FaInstagram, FaSpotify, FaYoutubeSquare} from 'react-icons/fa';

const SocialLinks = () => (
    <>
        <SocialLink link={'https://www.facebook.com/aopband/'} icon={<FaFacebookSquare/>}/>
        <SocialLink link={'https://www.instagram.com/aopband/'} icon={<FaInstagram/>}/>
        <SocialLink link={'https://www.youtube.com/user/andioliphilipp/'} icon={<FaYoutubeSquare/>}/>
        <SocialLink link={'https://open.spotify.com/artist/3vj4QdFe1WuB6d0CBPM8fN?si=zatUknwXSYSclQUZjnGlbw'}
                    icon={<FaSpotify/>}/>
    </>
);


export default SocialLinks;
