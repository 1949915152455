import React from 'react'
import styled from "styled-components";
import {FaBars} from 'react-icons/fa';
import {colors} from "../utils/styles";
import {breakpoints} from "../utils/responsive";

const NavigationButtonWrapper = styled.div`
  position: fixed;
  left: 1rem;
  top: 1rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 10;
  @media(min-width: ${breakpoints.lg}px) {
    display: none;    
  }
`;

const NavigationButtonButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.5rem;
  
  &:hover {
    color: ${colors.link};
  }
`;

const NavigationButton = ({onClick}) => (
    <NavigationButtonWrapper>
        <NavigationButtonButton onClick={onClick}>
            <FaBars/>
        </NavigationButtonButton>
    </NavigationButtonWrapper>
);

export default NavigationButton