import React from "react";
import styled from "styled-components"
import CartButton from "./cartButton";
import SocialLinks from "./socialLinks";
import {breakpoints} from "../utils/responsive";

const IconNavigationWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  z-index: 4;
  @media(min-width: ${breakpoints.md}px) {
    width: auto;  
    display: flex;
    > div {
      margin-left: 1rem;
    }
  }
`;

const IconNavigation = () => (
    <IconNavigationWrapper>
        <CartButton/>
        <SocialLinks/>
    </IconNavigationWrapper>
);

IconNavigation.propTypes = {
};

IconNavigation.defaultProps = {
};

export default IconNavigation;
