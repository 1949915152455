import React from 'react'
import styled from "styled-components";
import {FaShoppingCart} from 'react-icons/fa';
import {colors} from "../utils/styles";

const CartButtonWrapper = styled.div`
  width: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const CartButtonButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.5rem;
  margin: 0.7rem;
  &:hover {
    color: ${colors.link};
  }
`;

const CartButtonItemCount = styled.span`
  &:empty {
  display: none;
  }
  
  font-size: .75rem;
  background: ${colors.link};
  color: ${colors.background};
  border-radius: 50%;
  display: flex;
  position: absolute;
  width: 1rem;
  height: 1rem;
  justify-content: center;
  text-align: center;
  line-height: 1;
  right: 0;
`;
const CartButton = () => (
    <CartButtonWrapper>
        <CartButtonButton className={'cart-button snipcart-checkout'}>
            <FaShoppingCart/>
        </CartButtonButton>
        <CartButtonItemCount className="snipcart-items-count" />
    </CartButtonWrapper>
);

export default CartButton