import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

import Header from "./header";
import "./reset.css";
import "./aop.css";

import styled from "styled-components";
import {colors} from "../utils/styles";
import Footer from "./footer";
import Navigation from "./navigation";

const AopWrapper = styled.div`
  font-family: "Open Sans", sans-serif;
  background: ${colors.background};
  color: ${colors.text};
  line-height: 19px;
`;

const ContentWrapper = styled.div`
`;


const Layout = ({title, children}) => {
    const [navigationIsVisible, setNavigationIsVisible] = useState(false);
    const headerProps = children.props && children.props.content ? children.props.content : {};
    const navWrapper = useRef();

    useEffect(() => {
        if (navigationIsVisible) {
            disableBodyScroll(navWrapper.current);
        } else {
            enableBodyScroll(navWrapper.current)
        }

        return () => {
            clearAllBodyScrollLocks();
        }
    }, [navigationIsVisible]);

    return (
        <AopWrapper className={navigationIsVisible ? 'navigation-visible' : ''}>
            <Navigation ref={navWrapper} navigationIsVisible={navigationIsVisible}
                        toggleNavigation={setNavigationIsVisible}/>
            <Header {...headerProps} navigationIsVisible={navigationIsVisible} toggleNavigation={setNavigationIsVisible}
                    fallBackTitle={title}/>
            <ContentWrapper>
                <main>{children}</main>
                <Footer/>
            </ContentWrapper>
        </AopWrapper>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
