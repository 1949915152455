export const colors = {
    background: '#301c3d',
    backgroundLight: '#371f46',
    backgroundLighter: '#593A6C',
    link: '#ae6fb5',
    border: '#ae6fb5',
    text: '#ffffff',
    headline: '#e897ed',
};

export const fontWeights = {
    light: 300,
    regular: 400,
    bold: 600,
};