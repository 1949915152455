import React from "react";

import "./reset.css";
import "./aop.css";

import styled from "styled-components";
import {colors} from "../utils/styles";
import {Link} from "gatsby";
import NavigationButton from "./navigationButton";
import {breakpoints} from "../utils/responsive";

const NavigationWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  width: 300px;
  height: 100%;
  transform: translate3d(-320px,0,0);
  transition: transform 0.4s;
  background: ${colors.backgroundLight};
  
  &.visible {
    transform: translate3d(0,0,0);
  }
  
  @media(min-width: ${breakpoints.lg}px) {
    position: absolute;
    width: 100%;
    height: auto;
    transform: none;
    background: none; 
    top: 1.1rem;
    font-size: 1.2857142857rem;
    z-index: 4;
  }
`;
const NavigationInner = styled.nav`
  padding: 4rem 1rem 1rem;
  @media(min-width: ${breakpoints.lg}px) {
    padding: 0;
  }
`;

const NavigationList = styled.ul`
  @media(min-width: ${breakpoints.lg}px) {
    text-align: left;
  }
  @media(min-width: ${breakpoints.xl}px) {
    text-align: center;
  }
`;
const NavigationListItem = styled.li`
  margin-bottom: 1rem;
  a {
    display: block;
    text-decoration: none;
    color: ${colors.text};
    background: ${colors.backgroundLighter};
    padding: .5rem 1rem;
    border-radius: 7px;
  }
  
  @media(min-width: ${breakpoints.lg}px) {
    display: inline-block;
    font-family: 'Bebas Neue', cursive;
    font-size: 1.8rem;
    line-height: 1.6428571429rem;
    a {
      display: inline-block;
      background: none;
      border-radius: 0;
      color: ${colors.text};
      
      &:hover {
        color: ${colors.link};
      }
    }    
  }
`;

const Navigation = React.forwardRef(({toggleNavigation, navigationIsVisible}, ref) => {

    return (
        <NavigationWrapper ref={ref} className={navigationIsVisible ? 'visible' : ''}>
            <NavigationInner>
                <NavigationButton onClick={() => toggleNavigation(!navigationIsVisible)}/>
                <NavigationList>
                    <NavigationListItem><Link to={'/'}>Home</Link></NavigationListItem>
                    <NavigationListItem><Link to={'/news/'}>News</Link></NavigationListItem>
                    {/*<NavigationListItem><Link to={'/live-stream/'}>Live-Stream am 16.05.20</Link></NavigationListItem>*/}
                    <NavigationListItem><Link to={'/die-punkrock-polizei/'}>Podcast</Link></NavigationListItem>
                    <NavigationListItem><Link to={'/shop/'}>Shop</Link></NavigationListItem>
                    {/*<NavigationListItem><Link to={'/#diskografie'}>Diskographie</Link></NavigationListItem>*/}
                    {/*<NavigationListItem><Link to={'/musikvideos/'}>Musikvideos</Link></NavigationListItem>*/}
                </NavigationList>
            </NavigationInner>
        </NavigationWrapper>
    )
});

export default Navigation;