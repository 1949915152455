import React from "react";
import styled from "styled-components";
import SocialLinks from "./socialLinks";
import {colors, fontWeights} from "../utils/styles";
import {breakpoints} from "../utils/responsive";

const FooterWrapper = styled.footer`
  text-align: center;
  margin-top: 3rem;
  padding: 2rem 2.25rem;
  background: ${colors.backgroundLight};
  @media(min-width: ${breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;    
  }
`;

const FooterHeadline = styled.h4`
  color: ${colors.headline};
  text-transform: uppercase;
  text-align: center;
  font-family: 'Bebas Neue', cursive;
  font-size: 1.28rem;
  line-height: 1.6428571429rem;
  margin-bottom: 1rem;
`;

const FooterAddress = styled.p`
  font-weight: ${fontWeights.light};
  margin-bottom: 3rem;
  a {
    color: ${colors.link};
  }
`;

const FooterSocialLinkWrapper = styled.div`
  //display: flex;
  //justify-content: space-evenly;
  margin-bottom: 2rem;
  div {
    display: inline-block;
    width: 3rem;
  }
`;

const FooterColumn = styled.div`
 
`;

const Footer = () => {
    return <FooterWrapper>
        <FooterColumn>
            <FooterHeadline>Folge uns auf</FooterHeadline>
            <FooterSocialLinkWrapper>
                <SocialLinks/>
            </FooterSocialLinkWrapper>
        </FooterColumn>
        <FooterColumn>
            <FooterHeadline>Kontakt</FooterHeadline>
            <FooterAddress>
                AOP<br/>
                c/o Ministerium für Punk GbR<br/>
                Wachterstraße 12<br/>
                74172 Neckarsulm<br/>
                <a href="mailto:info@aop.band">info@aop.band</a><br/>
                <a href="/impressum/">Impressum</a>
            </FooterAddress>
        </FooterColumn>
        <FooterColumn>
            <FooterHeadline>Stolzer Partner von</FooterHeadline>
            <a href="https://vision-ears.de/" rel="noopener noreferrer" target="_blank"><img
                src={"/assets/vision-ears.png"}
                alt="Vision Ears"/></a>
        </FooterColumn>
    </FooterWrapper>
};

export default Footer;
